import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Terms({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-start min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg md:max-w-2xl lg:max-w-4xl sm:w-full sm:p-6">
              <div>
                {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                <div className="mt-3 text-lg text-left lg:text-xl sm:mt-5 text-swa-2">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Terms and conditions
                  </Dialog.Title>
                  <h3>1. AGREEMENT TO TERMS AND CONDITIONS OF USE</h3>
                  <br />
                  <p>
                    1.1 Thank you for visiting the Singapore Women’s Association
                    (“SWA”) website. The Terms and Conditions of Use apply to
                    the SWA website. BY ASCCESSING AND USING THIS SITE, YOU
                    AGREE TO BE BOUND BY THESE TERMS OF USE; IF YOU DO NOT
                    AGREE, DO NOT USE THE SITE.
                  </p>
                  <br />
                  <p>
                    1.2 This website is maintained by SWA. SWA reserves the
                    right to change, modify, add or remove any part of these
                    Terms and Conditions of Use, at any time as it thinks fit.
                    Changes to any of these Terms and Conditions of Use will be
                    posted on this page and it is your responsibility to check
                    the website periodically for changes. Such changes to these
                    Terms and Conditions of Use will not affect Terms and
                    Conditions of Use applicable to personal information at the
                    time provided on this SWA website. The use of this website
                    after such changes have been posted will constitute your
                    acceptance and agreement to such changes made and the
                    modified Terms and Conditions of Use.
                  </p>
                  <br />
                  <h3>2. INTERPRETATION</h3>
                  <br />
                  <p>In this document: -</p>
                  <p>
                    2.1 “SWA” refers to Singapore Women’s Association and
                    includes its Board Members, Committee Members, Volunteers
                    and Employees{" "}
                  </p>
                  <br />
                  <p>
                    2.2 “User” refers to the applicant, registrant, donor and/or
                    any third party.
                  </p>
                  <br />
                  <p>
                    2.3 There is a minimum donation / payment amount of SGD$10
                    and all sums shall be in whole dollars (no cents).{" "}
                  </p>
                  <br />
                  <p>
                    2.4 Transactions must be made using the means specified by
                    SWA including, without limitation, type of credit card or
                    bank.
                  </p>
                  <br />
                  <p>2.5 All donations are non-refundable.</p>
                  <br />
                  <h3>3. ACCESS</h3>
                  <br />
                  <p>
                    3.1 SWA reserves the right, at its sole discretion to deny
                    or restrict access to any portion or feature of this
                    website.
                  </p>
                  <br />
                  <p>
                    3.2 SWA reserves the right, at its sole discretion to
                    modify, suspend, interrupt the operation of the Site or
                    discontinue, temporarily or permanently the site. Such right
                    may be exercised without prior notice for reasons that may
                    or may not be beyond SWA’s control, including but not
                    limited to internet, email, network, computer hardware and /
                    or software malfunction, failure, damage or delay.
                  </p>
                  <br />
                  <h3>4. DISCLAIMER</h3>
                  <br />
                  <p>
                    4.1 To the fullest extent permitted by law, SWA does not
                    warrant and disclaims any and all other warranties, whether
                    express or implied, including but not limited to, the site
                    being uninterrupted or error-free, or that defects will be
                    corrected or that it is and will be free of all viruses
                    and/or other harmful elements.
                  </p>
                  <br />
                  <h3>5. LIABILITY</h3>
                  <br />
                  <p>
                    5.1 SWA shall not be liable in any event to any user for
                    failure of any service or any damage (including but not
                    limited to special, consequential, or exemplary damages) or
                    loss of any kind in connection with the use of the site,
                    including but not limited to any damage or loss suffered
                    (including any failure to receive any payments in connection
                    with access to, use of, denial of access or restricted
                    access to, modification, suspension and/or discontinuation
                    of the site.
                  </p>
                  <br />
                  <p>
                    5.2 Without prejudice to the generality of the foregoing,
                    SWA shall not be liable for any defect, default, deficiency
                    or malfunction in any device, computer, internet service
                    provider or telecommunication system or network, viruses or
                    other malicious software or denial of service attacks that
                    may affect the use of the site
                  </p>
                  <br />
                  <p>
                    5.3 User agrees that use of SWA is conditioned upon user’s
                    waiver of any right to sue SWA for damages or losses related
                    to the use of the site.
                  </p>
                  <br />
                  <h3>6. PRIVACY &amp; POLICY DISCLAIMER</h3>
                  <br />
                  <p>
                    6.1 The following sets out the Terms and Conditions of Use
                    applicable to SWA’s collection and use of the User’s
                    personal data via this website:
                  </p>
                  <br />
                  <h4>- Information collected</h4>
                  <br />
                  <p>
                    6.2 SWA collects information from the User when the User
                    registers for an event with SWA. When registering on the SWA
                    site, as appropriate, the User may be asked to enter name,
                    e-mail address, mailing address, phone number. SWA does not
                    collect / store credit card information.
                  </p>
                  <br />
                  <h4>Use of information</h4>
                  <br />
                  <p>
                    6.3 Any of the information collected may be used to process
                    transactions, issue receipts, relating to registration, and
                    enrolment for SWA events.
                  </p>
                  <br />
                  <p>
                    6.4 Personal information collected via the website will not
                    be sold, exchanged, transferred, or given to any other
                    company for any reason whatsoever, without the User’s
                    consent, other than the purposes related to the User’s
                    registration for SWA events.
                  </p>
                  <br />
                  <h4>Protecting information</h4>
                  <br />
                  <p>
                    6.5 A variety of security measures have been implemented to
                    maintain the safety of your personal information (name,
                    email address, mailing address, phone number) collected via
                    this SWA website.
                  </p>
                  <br />
                  <h4>Use of cookies</h4>
                  <br />
                  <p>
                    6.6 This website uses cookies. Cookies allow SWA to
                    recognise the User’s browser and to capture and remember
                    certain information. The information stored on such cookies
                    (which does not include credit information) shall be stored
                    on SWA’s secure server and used only to help SWA remember
                    and process the items in the User’s shopping cart when the
                    User registers for SWA events via this SWA website.{" "}
                  </p>
                  <br />
                  <h4>Disclosure of information to third parties</h4>
                  <br />
                  <p>
                    6.7 This website may contain links to other independent
                    third-party websites. SWA does not sell, trade, or otherwise
                    transfer to third parties’ personal information except that
                    access to personal information may be granted to trusted
                    third parties who assist in operating the SWA website,
                    conducting business, or servicing the User so long as those
                    parties agree to keep supplied personal information
                    confidential. SWA may also release personal information when
                    the release is appropriate to comply with any applicable
                    law, enforce site policies, or protect SWA or others’
                    rights, property, or safety.{" "}
                  </p>
                  <br />
                  <h4>Online Privacy Policy Only</h4>
                  <br />
                  <p>
                    {" "}
                    6.8 The Privacy Policy terms set out in these Terms and
                    Conditions of Use applies only to information collected
                    throughout the SWA website.
                  </p>
                  <br />
                  <h4>Consent</h4>
                  <br />
                  <p>
                    6.9 By providing personal information on this SWA website,
                    the User consents to and agrees to abide by these Terms and
                    Conditions of Use.{" "}
                  </p>
                  <br />
                  <h3>7. CONTACT DETAILS</h3>
                  <br />
                  <p>
                    7.1 If there are any questions regarding these terms and
                    conditions, the User may contact SWA at Blok 409 Serangoon
                    Central #01-303 Singapore 550409, at: -
                    contactus@singaporewomenassociation.org or Tel: 65-62868702{" "}
                  </p>
                  <br />
                  <h3>8. CANCELLATION &amp; REFUND POLICIES</h3>
                  <br />
                  <p>
                    8.1 Please refer to SWA Website: Course Changes,
                    Cancellation &amp; Refund Policies for details relating to
                    changes to registered events, event cancellations and SWA’s
                    refund policies. All requests for cancellations should be
                    made known to the SWA Office either by telephone:
                    65-62868702 or email:
                    contactus@singaporewomenassociation.org
                  </p>
                  <br />
                  <h3>9. MISCELLANEOUS</h3>
                  <br />
                  <p>
                    9.1 No failure to exercise, nor any delay in exercising, on
                    the part of SWA, any right under these Terms and Conditions
                    of Use shall operate as a waiver thereof.
                  </p>
                  <br />
                  <p>
                    {" "}
                    9.2 The headings in this Agreement are for ease of reference
                    only and shall not be taken into consideration in the
                    construction or interpretation of this Agreement.
                  </p>
                  <br />
                  <p>
                    9.3 If any provision of this Agreement is deemed unlawful or
                    unenforceable, such provision shall be severed from this
                    Agreement without prejudice to all other provisions which
                    shall remain enforceable.
                  </p>
                  <br />
                  <p>
                    9.4 The construction and interpretation of this Agreement
                    shall be subject to and governed by the laws of Singapore.
                    The user agrees to submit to the exclusive jurisdiction of
                    the courts in Singapore.
                  </p>
                  <br />
                  <p>
                    9.5 A person who is not a party to this Agreement shall have
                    no right under the Contracts (Rights of Third Parties) Act
                    (Cap 53B) to enforce any of its Terms and Conditions of Use.
                  </p>
                  <br />
                  <p>
                    9.6 SWA reserves the right, at its sole discretion, to vary
                    the above Terms and Conditions of Use from time to time.
                    This Agreement may not be varied except in writing signed by
                    a duly authorised officer or representative of SWA.
                  </p>
                  <br />
                  <p>
                    9.7 Use and / or continued use of site constitute agreement
                    by the User to be bound by the terms and conditions, which
                    may change from time to time. If the User does not agree to
                    these terms and conditions, the User should cease use of the
                    SWA Site.
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-3 hover:bg-swa-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-4 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
