import React, { useState } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from "gatsby";
import Terms from "../../components/sections/home/terms";

const Footer = () => {
  const [open, setOpen] = useState(false);

  const clickHandler = () => {
    setOpen(!open);
  };
  const keyDownHandler = e => {
    if (e.keyCode === 32) {
      setOpen(!open);
    }
  };

  return (
    <>
      <Terms open={open} setOpen={setOpen} />
      <footer className="w-full p-10 text-white bg-swa-3 ">
        <div className="container flex flex-col justify-center mx-auto -top-20 lg:justify-between lg:flex-row ">
          <div className="text-center lg:text-left">
            <h4 className="mb-4 ">&copy;&nbsp;Singapore Women's Association</h4>
            <p className="text-sm text-white">
              409 Serangoon Central, #01-303, Singapore 550409
            </p>
            {/* <p className="text-sm text-white">+65 6286 8702</p> */}

            <div className="flex justify-center my-3 space-x-5 lg:justify-start lg:mb-0">
              <div className="text-2xl">
                <a
                  href="https://www.facebook.com/singaporewomenassociation"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
              </div>
              <div className="text-2xl">
                <a
                  href="https://www.instagram.com/singaporewomensassociation/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
            <div
              className="mt-0 text-sm text-white underline cursor-pointer lg:mt-3"
              onClick={clickHandler}
              role="button"
              onKeyDown={keyDownHandler}
              tabIndex={0}
            >
              Terms &amp; conditions
            </div>
          </div>
          {/* <div>
            <div className="max-w-sm px-3 py-4 mx-auto mt-5 border border-white rounded-lg lg:mt-0 ">
              <p className="text-white ">
                <span className="block leading-relaxed text-center">
                  If you are in urgent need of assistance due to domestic
                  violence, please contact relevant helplines at
                  <span className="inline-flex items-center justify-center px-2 py-0 mx-1 text-white border border-transparent rounded-sm bg-swa-1 hover:bg-swa-2 hover:text-white">
                    <a
                      href="https://actagainstviolence.org.sg/helplines/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      A.C.T. website
                    </a>
                  </span>
                </span>
              </p>
            </div>
          </div> */}
          {/* <div className="justify-between mt-10 space-x-2 lg:mt-0 lg:flex md:space-x-24"> */}
          <div className="w-full px-3 py-4 mx-auto mt-5 border border-white rounded-lg lg:mx-0 md:w-3/12 sm:w-1/2 lg:mt-0">
            <div>
              <h5 className="mb-4 font-bold ">Forms</h5>
              <ul className="space-y-2 text-sm font-light">
                {/* <li className="cursor-pointer">
                  <Link to="/forms/mspi">MSPI Application</Link>
                </li> */}
                <li className="cursor-pointer">
                  <Link to="/forms/volunteer">Volunteer</Link>
                </li>
                <li className="cursor-pointer">
                  <Link to="/participate/sponsor">Sponsorship</Link>
                </li>
                <li className="cursor-pointer">
                  <Link to="/forms/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
