import React from "react";
import { FaHome, FaHandsHelping, FaBook } from "react-icons/fa";
import { BsFillPeopleFill, BsCalendar } from "react-icons/bs";

export const mainNav = [
  {
    id: 1,
    text: "Home",
    url: "/",
    icon: <FaHome />,
  },
  {
    id: 2,
    text: "What",
    url: "#",
    icon: <BsFillPeopleFill />,
  },
  {
    id: 3,
    text: "How",
    url: "#",
    icon: <FaBook />,
  },
  {
    id: 4,
    text: "Events",
    url: "/events/",
    icon: <BsCalendar />,
  },
  {
    id: 5,
    text: "Participate",
    url: "/participate/",
    icon: <FaHandsHelping />,
  },
];

export const subNav1 = [
  {
    id: 21,
    text: "History",
    url: "/what/history",
  },
  {
    id: 22,
    text: "Our board",
    url: "/what/about",
  },
  {
    id: 23,
    text: "Founding President",
    url: "/what/message",
  },
  {
    id: 24,
    text: "Our work",
    url: "/what/work",
  },
  {
    id: 25,
    text: "Digitalisation",
    url: "/what/digital",
  },
];

export const subNav2 = [
  {
    id: 30,
    text: "Fundraising",
    url: "section",
  },
  {
    id: 31,
    text: "Annual Charity Dinner",
    url: "/how",
  },
  {
    id: 32,
    text: "Miss Singapore Pageant International",
    url: "/mspi/",
  },
  {
    id: 33,
    text: "Progammes",
    url: "section",
  },
  {
    id: 34,
    text: "Singapore VisionCare Programme",
    url: "/how/svp",
  },
  {
    id: 35,
    text: "Lunar New Year Lunch",
    url: "/how/newyearlunch",
  },
  {
    id: 36,
    text: "SWA Symrise Project Rebuild",
    url: "/how/rebuild",
  },
  {
    id: 37,
    text: "SWA Project Taking Charge",
    url: "/how/takingcharge",
  },
  {
    id: 38,
    text: "Ren Ci Hospital",
    url: "/how/renci",
  },

  {
    id: 39,
    text: "Laughter Yoga",
    url: "/how/laughteryoga",
  },
  // {
  //   id: 39,
  //   text: "Project Taking Charge",
  //   url: "/how/takingcharge",
  // },

  {
    id: 391,
    text: "Federation of Asia-Pacific Women's Asssocation",
    url: "/how/fawa",
  },
];

export const subNav3 = [
  {
    id: 51,
    text: "Donate",
    url: "/participate/donate",
  },
  {
    id: 52,
    text: "Volunteer",
    url: "/participate/volunteer",
  },
  {
    id: 53,
    text: "Sponsor",
    url: "/participate/sponsor",
  },
];
